.ca-table{
    padding-bottom: 100px;

    table{
        margin-left: auto;
        margin-right: auto;
    }

    &-flex{
        display: flex;
        flex-direction: column;
    }

    &-title{
        display: flex;
        align-items: baseline;
        justify-content: center;
    }
    
    
    &-thead-react{
        height: 140px;
      
           tr{
                th:nth-child(1){
                    display: none;
                }

               th:nth-child(3){
                   display: none;
               }
           }

           th{
            font-size: 1.6rem;;
            font-weight: bold;
            vertical-align: initial;
            padding: 10px;
      
            text-align: center;
        }

        select{
            width: 150px;
            height: 40px;
            margin-top: 24px;
        }
       
    }

    &-thead-manual{
        th{
            font-size: 1.6rem;;
            font-weight: bold;
            vertical-align: initial;
            padding: 10px;
            padding-left: 0;
            padding-right: 0;
        }
    }

    tbody{
        border-bottom: 5px solid $color-secondary;
        border-top: 5px solid $color-secondary;

        // hide columns but not filters

        tr{

            // Numero de denuncias
            td:nth-child(1){
                text-align: center;
            }

            // Fecha de ingreso
            td:nth-child(2){
                text-align: center;
            }

            td:nth-child(5){
                display: none;
            }

            // Canal denuncia
            td:nth-child(4){
                width: 150px;
                padding-left: 20px;
            }

            td:nth-child(6){
                display: none;
            }
        }



    }

    td{
        font-size: 1.6rem;;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .ca-button{
        font-size: 1.6rem;
        width: 140px;
    }

    &-date{
        font-size: 1.6rem;;
        height: 40px;
    }
}

.ca-table-detail{
    
    margin-bottom: 60px;
    tbody{
        border-top: 5px solid $color-secondary;

    }

    th{
        font-size: 1.6rem;;
        font-weight: bold;
        vertical-align: initial;
        padding: 10px;
    }

    td{
        font-size: 1.6rem;;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .ca-button{
        font-size: 1.6rem;;
        width: 200px;
    }
}