.ca-pagination{
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;

  
    button{
        border: 0;
        background-color: $color-secondary;
        color: white;
        border-radius: 4px;
        padding: 5px 10px;
        font-size: 1.6rem;

        &:disabled {
            background-color: #09999970
        }
    }

    span{
        font-size: 1.6rem;
        padding-left: 20px;
    }
}