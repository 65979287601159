.ca-content {
  .ca-card {
    height: 100%;
    .container {
      display: flex;
      flex-direction: column;
      height: 100%;

      .ca-button {
        margin-top: auto;
      }
    }
  }
}
