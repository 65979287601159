.ca-modal {
  .modal-xl {
    max-width: 1306px;
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-body {
    padding-left: 320px;
    padding-right: 320px;
    text-align: center;
  }

  h2 {
    margin-bottom: 40px;
  }

  h3 {
    font-weight: bold;
    font-size: 2.6rem;
    color: $color-secondary;
  }

  .card {
    padding: 40px;
    margin-bottom: 40px;
    text-align: center;
  }

  .nav-link {
    padding: 0;
  }

  .btn-close {
    border: 1px solid white;
    border-radius: 69px;
    background-color: grey;
    color: white;
    padding: 0.5rem 0.5rem;
    margin: 1.5rem 2.5rem -0.5rem auto;
    width: 3em;
    height: 3em;
  }
}

// Mobile
@media screen and (max-width: $md) {
  .ca-modal {
    .modal-body {
      padding-left: initial;
      padding-right: initial;
    }
  }
}