html{
    font-size: 62.5%;
}

*{
    font-family: 'Quicksand', sans-serif;
}

h2{
    color: $color-primary;
    font-size: 2.1rem;
    font-weight: bold;

    span{
        color: $color-text;
        font-size: 2.1rem;
        font-weight: bold;
    }
}

h3{
    color: $color-secondary;
    font-size: 2.1rem;
    font-weight: bold;
}

p{
    color: $color-text;
    font-size: 1.6rem;
}

form{

    label{
        font-size: 1.4rem;
        line-height: 3.2rem;
        margin-bottom: 0px;
    }


    .form-control{
        font-size: 1.4rem;
        border-radius: 4px;
        padding-bottom: 10px;
        padding-top: 10px;
        margin-bottom: 10px;
    }

    .ca-alert{
        padding-top: 10px;
        p{
            font-size: 1.6rem;
            margin-bottom: 0;
        }
    }

    .form-label{
        margin-bottom: 0px;
    }


    textarea.form-control{
        height: 177px;
    }
}