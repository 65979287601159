.ca-card{
    background-color: $color-secondary-light;
    border-radius: 10px;
    border: none;

    h3{
        text-align: center;
        padding-bottom: 35px;
    }

    &__list{
        padding: 20px;
        margin-bottom: 20px;
        height: 100%;
        border-radius: 10px;
        background-color: $color-secondary;
        
        ul{
            li{
                color: white;
                font-size: 1.6rem;
                line-height: 3.2rem;
                position: relative;
                list-style: none;

                &:before {
                    content: "*";
                    position: absolute;
                    top: 5px;
                    left: -14px;
                  }
            }

          
        }
    }
}

// Mobile
@media screen and (max-width: $md) {
    .ca-card {
        margin-bottom: 40px;
    }
  }