.ca-detail{
    &--content{
        p{
            padding-bottom: 44px;
        }

        h4{
            font-size: 1.8rem;
            font-weight: bold;
        }

        .ca-button--file{
            width: 275px;
        }
    }
}