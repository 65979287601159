.ca-button{
    font-size: 1.8rem;
    font-weight: bold;
    color: white;
    background-color: $color-primary;
    border-radius: 101px;
    width: 100%;

    &:hover{
        transition: all 0.3s ease-in-out;
    }

    &--file{
        border-radius: 5px;
        background-color: #EBEBEB;
        color: $color-text;
        font-weight: initial;
        border: 1px solid grey;
    }

    &--back{
        background-color: #EBEBEB;
        color: $color-text;
        font-weight: initial;   
        cursor: pointer;
        border: 1px solid #dbdbdb;
        width: 120px;
        text-align: center;
        font-size: 1.6rem;

        &:hover{
            transition: all 0.3s ease-in-out;
            background-color: $color-secondary;
            color: white;
        }
    }

    &--secondary{
        background-color: $color-secondary;
    }

    &--submit{
        width: 25%;
    }
}