  p b {
    font-weight: bold;
  }
  
  .clear {
    clear: both;
  }
  
  .wow {
    visibility: hidden;
  }
  
  .fadein {
    opacity: 0;
  }
  
  .hidden_a {
    opacity: 0;
  }
  
  .visible {
    opacity: 1;
  }
  
  .wrapper {
    position: relative;
  }
  
  .wrapper.wrapper-home {
    padding-top: 148px;
  }
  
  .wrap {
    max-width: 100%;
    width: 1180px;
    margin: 0 auto;
    padding: 0 40px;
  }
  
  .row.no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  
  .row.no-gutters>[class^="col-"],
  .row.no-gutters>[class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
  }
  
  .row.row-small {
    margin-right: -10px;
    margin-left: -10px;
  }
  
  .row.row-small>[class^="col-"],
  .row.row-small>[class*=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
  
  .row.row-big {
    margin-right: -50px;
    margin-left: -50px;
  }
  
  .row.row-big>[class^="col-"],
  .row.row-big>[class*=" col-"] {
    padding-right: 50px;
    padding-left: 50px;
  }
  
  .row.row-20 {
    margin-right: -10px;
    margin-left: -10px;
  }
  
  .row.row-20>[class^="col-"],
  .row.row-20>[class*=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
  
  .flex-stretch {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .flex-between {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .flex-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .flex-top {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .flex-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .pie-nota{
    margin-bottom: 130px;
    margin-top: 20px;
  }
  
  p b {
    font-weight: bold;
  }
  
  a,
  button {
    transition: all 0.3s ease;
  }
  
  a {
    text-decoration: none;
  }
  
  a:hover {
    color: #088;
    text-decoration: none;
  }
  
  
  
  .btn-full {
    width: 100%;
  }
  
  .btn:last-child {
    margin-bottom: 0;
  }
  
  .btn.btn-full {
    width: 100%;
  }
  
  .btn:hover {
    color: #fff;
  }
  
  .btn-naranjo {
    background: #EA6607;
  }
  
  .btn-naranjo:hover {
    background: #d65d06;
  }
  
  .btn-borde-naranjo {
    border: 1px solid #EA6607;
    color: #EA6607;
  }
  
  .btn-borde-naranjo:hover {
    background: #EA6607;
    color: #fff;
  }
  
  
  .btn-morado {
    background: #6C2183;
  }
  
  .btn-morado:hover {
    background: #561A69;
    color: #fff;
  }
  .btn-borde-azul {
    border: 1px solid #1d3e8b;
    color: #1d3e8b;
  }
  
  .btn-borde-morado {
    border: 1px solid #6C2183;
    color: #6C2183;
  }
  
  .btn-borde-morado:hover {
    background: #6C2183;
    color: #fff;
  }
  
  .btn-verde {
    background: #009999;
  }
  
  .btn-verde:hover {
    background: #058989;
    color: #fff;
  }
  
  .btn-borde-verde {
    border: 1px solid #009999;
    color: #009999;
  }
  
  .btn-borde-verde:hover {
    background: #009999;
    color: #fff;
  }
  
  .btn-gris {
    background: #666666;
  }
  
  .btn-gris:hover {
    background: #434040;
  }
  
  .btn-borde-gris {
    border: 1px solid #666666;
    color: #666666;
  }
  
  .btn-borde-gris:hover {
    background: #666;
    color: #fff;
  }
  
  
  
  /*--HEADER--*/
  header {
    background: #EA6607;
    padding: 0px 0;
  }
  
  header .logo {
    padding: 0px 0;
  }
  
  header .logo img {}
  
  header nav {}
  
  header nav ul {
    margin-bottom: 0;
    display: inline-block;
  }
  
  header nav ul li {
    display: inline-block;
    position: relative;
  }
  
  header nav ul li a {
    color: #fff;
    display: block;
    padding: 33px 10px;
    transition: all 0.2s ease;
    font-family: Quicksand,Arial,Helvetica,sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 1.6rem;
  }
  
  header nav ul li.ml-3 a {
    background: #fff;
    color: #ea6607;
    text-transform: uppercase;
    font-family: Roboto;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  
  header nav ul li a i {
    font-size: 12px;
  }
  
  header nav ul li:hover a {
    background: #fff;
    color: #333;
  }
  
  header nav ul li>ul.submenu {
    position: absolute;
    top: 85px;
    background: #fff;
    padding: 14px 6px;
    z-index: 10;
    width: 330px;
    -webkit-transform: translateX(-20%);
    -ms-transform: translateX(-20%);
    transform: translateX(-20%);
    visibility: hidden;
    opacity: 0;
    left: 66px;
  }
  
  header nav ul li:hover>ul.submenu {
    opacity: 1;
    visibility: visible;
  }
  
  header nav ul li>ul.submenu li {
    display: block;
  }
  
  header nav ul li>ul.submenu li a {
    color: #333333;
    font-size: 14px;
    line-height: 18px;
    display: inline-block;
    padding: 3px 10px;
    border-radius: 6px;
    font-family: 'Quicksand', sans-serif;
    border: 1px solid transparent;
  }
  
  header nav ul li>ul.submenu li a:hover {
    background: #ECECEC;
    border: 1px solid #DEDEDE;
  }
  
  header nav ul li>ul.submenu li span {
    color: #6E3636;
    font-size: 12px;
    line-height: 18px;
    display: inline-block;
    padding: 3px 12px;
  }
  
  header nav .lupa {
    display: inline-block;
    width: 44px;
    height: 44px;
    line-height: 44px;
    color: #fff;
    text-align: center;
    margin-left: 10px;
    cursor: pointer;
  }
  
  header nav .lupa i {}
  
  header .btn-menu {
    display: none;
  }
  
  header .btn-menu span {
    width: 30px;
    height: 5px;
    background-color: #fff;
    margin: 6px 0;
    transition: 0.4s;
    display: block;
  }
  
  header .btn-menu.open span:nth-child(1) {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
  }
  
  header .btn-menu.open span:nth-child(2) {
    opacity: 0;
  }
  
  header .btn-menu.open span:nth-child(3) {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
  }
  
  .menu-mobile {
    display: none;
  }
  
  /*--FIN HEADER--*/
  
  /*--BANNER--*/
  .banner {
    position: relative;
  }
  
  .banner .wrap {
    position: relative;
  }
  
  .banner picture {}
  
  .banner picture img {
    width: 100%;
  }
  
  .logo-seguro {
    position: absolute;
    top: 30px;
    right: 80px;
  }
  
  .logo-seguro img {}
  
  .texto-banner {
    width: 235px;
    position: absolute;
    top: 0;
    left: 100px;
  }
  
  .texto-banner .descuento {
    font-family: 'Interstate';
    color: #fff;
    background: rgba(0, 153, 153, 0.9);
    padding: 20px 25px;
    margin-bottom: 10px;
    font-display: swap;
  }
  
  .texto-banner .descuento .des1 {
    font-size: 88px;
    line-height: 80px;
    font-weight: bold;
    font-family: 'Interstate';
    letter-spacing: -4px;
    display: inline-block;
    vertical-align: middle;
    font-display: swap;
  }
  
  .texto-banner .descuento .des2 {
    font-size: 58px;
    line-height: 40px;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
  }
  
  .texto-banner .descuento .des2 span {
    font-size: 15px;
    line-height: 17px;
    margin-top: 7px;
    font-weight: bold;
    display: block;
  }
  
  .texto-banner .descuento h4 {
    font-size: 28px;
    line-height: 30px;
    margin: 10px 0 0;
  }
  
  .texto-banner a.btn {
    font-size: 18px;
    font-weight: bold;
    border-radius: 0;
    padding: 14px;
  }
  .landing-banner{
    position: absolute;
    bottom: 100px;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    right: 480px;
  }
  /*--FIN BANNER--*/
  figure {
    margin: 0;
  }
  
  .p-15 {
    padding: 15px 0;
  }
  
  .p-20 {
    padding: 20px 0;
  }
  
  .p-40 {
    padding: 40px 0;
  }
  
  .p-60 {
    padding: 60px 0;
  }
  
  .m-10 {
    margin-bottom: 10px;
  }
  
  .m-15 {
    margin-bottom: 15px;
  }
  
  .m-20 {
    margin-bottom: 20px;
  }
  
  .m-40 {
    margin-bottom: 40px;
  }
  
  .m-60 {
    margin-bottom: 60px;
  }
  
  /*--SERVICIOS HOME--*/
  .servicios-home .item-serv {
    margin: 0 8px;
    display: block;
    text-align: center;
    padding: 12px 10px;
    width: 180px;
    
  }
  
  .servicios-home .item-serv:hover {
    opacity: 0.85;
  }
  
  .servicios-home .item-serv figure {
    width: 70px;
    height: 70px;
    background: #009999;
    border-radius: 50%;
    margin: 0 auto 10px;
  }
  
  .servicios-home .item-serv figure img {}
  
  .servicios-home .item-serv h4 {
    font-size: 16px;
    color: #4A4A4A;
    margin: 0;
    text-transform: uppercase;
  }
  
  /*--FIN SERVICIOS HOME--*/
  
  /*--ARTICULOS HOME--*/
  .box-article {}
  
  .grid .box-article {
    margin-bottom: 35px;
  }
  
  .box-article .categoria {
    text-align: right;
    color: #6E3636;
    font-size: 11px;
    padding-bottom: 5px;
  }
  
  .box-article figure {
    position: relative;
  }
  
  .box-article figure img {
    width: 100%;
    transition: all 0.3s ease;
  }
  
  .box-article:hover figure img {
    opacity: 0.9;
  }
  
  .box-article h3 {
    font-size: 32px;
    font-weight: bold;
    color: #fff;
    margin: 0;
    position: absolute;
    bottom: 5px;
    left: 15px;
    text-shadow: 0px 1px 6px rgba(0, 0, 0, 1);
  }
  
  .box-article h3 a {
    color: #fff;
  }
  
  .box-article h4 {
    font-size: 21px;
    line-height: 24px;
    font-weight: bold;
    color: #fff;
    margin: 0;
    position: absolute;
    bottom: 7px;
    left: 15px;
    text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.5);
  }
  
  .box-article h4 a {
    color: #fff;
  }
  
  .box-article .texto-article {
    padding: 15px;
    -webkit-box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.3);
  }
  
  .box-article .texto-article p {
    font-size: 1rem;
    color: #4a4a4a;
    line-height: 150%;
  }
  
  .box-article .texto-article a {}
  
  /*--FIN ARTICULOS HOME--*/
  
  /*--DATOS CONTACTO--*/
  .datos-contacto {}
  
  .datos-contacto .item-dato {
    width: 33.33%;
    position: relative;
    padding-left: 70px;
    border-right: 1px solid #F1F1F1;
  }
  
  .datos-contacto .item-dato:last-child {
    border: none;
  }
  
  .datos-contacto .item-dato i {
    color: #B9B9B9;
    font-size: 30px;
    position: absolute;
    left: 30px;
    top: 4px
  }
  
  .datos-contacto .item-dato p {
    margin-bottom: 0;
  }
  
  .mapa {
    width: 100%;
    height: 390px;
  }
  
  .mapa iframe {
    width: 100%;
    height: 100%;
  }
  
  /*--FIN DATOS CONTACTO--*/
  
  /*--FOOTER--*/
  footer {
    padding: 30px 0;
    height: 295px;
    background-color: $color-primary;
    position: absolute;
    bottom: 0;
    width: 100%;

    li{
        font-size: 1.6rem;
    }

    h6{
        font-size: 1.6rem;
        font-weight: bold;
    }

    .text-light{
        font-size: 1.6rem;
    }
  }
  
  footer .logo-footer {
    margin-right: 20px;
  }
  
  footer .m-footer {
    padding: 0 20px;
  }
  
  footer .m-footer h4 {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    font-family: 'Quicksand', sans-serif;
    margin: 0 0 10px;
  }
  
  footer .m-footer a {
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    color: #fff;
    font-weight: normal;
    margin-bottom: 10px;
    display: block;
  }
  
  footer .m-footer a:hover {
    color: #fff;
    text-decoration: underline;
  }
  
  footer .redes-sociales {
    color: #fff;
  }
  
  /*--FIN FOOTER--*/
  .t-2 {
    font-size: 1.7rem;
    font-weight: 500;
    color: #4a4a4a;
  }
  
  .t-3 {
    font-size: 24px;
    color: #666666;
    font-weight: bold;
    margin: 0 0 20px;
  }
  
  .t-3.small {
    font-size: 21px;
  }
  
  .t-3.verde,
  .t-5.verde {
    color: #00888B;
  }
  
  .t-3.naranjo,
  .t-5.naranjo {
    color: #EA6607;
  }
  
  /* colores seguros */
  .btn-azul { background-color: #1D3E8B ; }
  .azul .t-3 { color: #1D3E8B ; }
  
  .morado .t-3 {
    color: #6C2183;
  }
  
  .naranjo .t-3 {
    color: #EA6607;
  }
  .pasos {
    display: flex;
    max-width: 840px;
    margin: 0 auto;
    width: 100%;
    justify-content: space-between;
  }
  
  .t-5 {
    font-size: 18px;
    color: #666666;
    font-weight: bold;
    margin: 0 0 20px;
  }
  
  .t-5.paso-sim {
    position: relative;
    display: flex;
    width: 28%;
    align-items: flex-start;
    opacity: .4;
  }
  .t-5.paso-sim.activo {
    opacity: 1;
  }
  
  .t-5 span {
    font-size: 18px;
    color: #666666;
    font-weight: bold;
    width: 36px;
    height: 36px;
    min-width: 36px;
    border: 2px solid #666666;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    line-height: 32px;
    margin-right: 9px;
  }
  
  /*--SEGUROS--*/
  .seguros {}
  
  .seguros picture {}
  
  .seguros picture img {
    width: 100%;
  }
  
  .seguros picture h3 {
    display: none;
  }
  
  .botones-seguro {
    padding: 0 20px;
    margin-top: -30px;
    margin-bottom: 30px;
  }
  
  .botones-seguro .btn {
    padding: 14px 20px;
  }
  
  .uppercase {
    text-transform: uppercase;
  }
  
  .seguros h3.t-3,
  .seguros h5.t-5 {
    margin-bottom: 8px;
    margin-top: 20px;
  }
  
  .w-beneficios {
    margin-bottom: 20px;
  }
  
  .item-beneficio {
    background: #1E9EA0;
    border-radius: 30px 0 30px 0;
    height: 105px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 20px;
    margin-bottom: 5px;
  }
  
  .item-beneficio .icono {
    background: #00888B;
    border-radius: 30px 0 30px 0;
    height: 100%;
    width: 116px;
    min-width: 116px;
    margin-right: 20px;
    color: #fff;
  }
  
  .item-beneficio .icono i {
    font-size: 44px;
  }
  
  .item-beneficio .icono p {
    margin-bottom: 0;
    font-size: 44px;
    line-height: 28px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
  }
  
  .item-beneficio .icono p span {
    display: block;
    min-width: 100%;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: -8px;
  }
  
  .item-beneficio p {
    margin-bottom: 0;
    color: #fff;
    font-size: 16px;
    line-height: 20px;
  }
  
  .item-beneficio p b {}
  
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
  
  .botonera {
    flex-wrap: wrap;
    padding: 0 20px;
  }
  
  .botonera.botonera-desktop {}
  
  .botonera.botonera-mobile {
    display: none;
  }
  
  .botonera .item-botonera {
    -webkit-box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.3);
    min-width: 48%;
    width: 48%;
    height: 66px;
    padding: 10px 30px;
    margin-bottom: 10px;
  }
  
  .botonera .item-botonera:hover {
    opacity: 0.8;
  }
  
  .botonera .item-botonera i {
    color: #00888B;
    font-size: 26px;
    margin-right: 20px;
  }
  
  .botonera .item-botonera p {
    font-size: 15px;
    line-height: 18px;
    color: #333;
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
  }
  
  
  .t-5.paso-sim a {
    display: flex;
    color: #666;
  }
  /*--FIN SEGUROS--*/
  
  /*--ACORDEON--*/
  .acordeon {
    margin: 50px 0;
  }
  
  .card {
    border: none;
    border-radius: 0;
    margin-bottom: 15px;
    -webkit-box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.2);
  }
  
  .card-header {
    padding: 0;
    text-align: left;
    border-radius: 0;
    position: relative;
    border: none;
  }
  
  .card-header.collapsed {
    border: none;
    border-radius: 5px;
    background: #fff;
    ;
    border: none;
  }
  
  .card-header:not(.collapsed) {
    background: #fff;
    border-radius: 0px;
    color: #333;
  }
  
  .card-header.collapsed:after {
    content: "\f078";
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    font-size: 24px;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 12px;
    color: #fff;
    font-size: 14px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #FF6C2C;
    padding-top: 12px;
    text-align: center;
  }
  
  .card-header:not(.collapsed):after {
    color: #fff;
    content: "\f077";
    font-weight: 900;
    font-family: 'Font Awesome\ 5 Free';
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 12px;
    background: #9E9E9E;
    width: 40px;
    height: 40px;
    padding-top: 10px;
    border-radius: 50%;
    text-align: center;
    font-size: 14px;
  }
  
  .card .content {
    padding: 0 20px 10px;
  }
  
  .card-header button,
  .card-header h3 {
    color: #333;
    width: 100%;
    text-align: left;
    font-size: 20px;
    line-height: 30px;
    font-weight: normal;
    border-radius: 0;
    background: none;
    border: none;
    padding: 19px 20px 12px;
  }
  
  .card-header button:hover,
  .card-header button:focus {
    text-decoration: none;
    color: #333;
  }
  
  .card-body {
    padding: 20px;
  }
  
  .card-body h4,
  .card h4 {
    color: #6C6C6C;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  
  .card-body p {
    color: #333333;
    font-size: 15px;
    line-height: 21px;
    margin-bottom: 30px;
    font-family: 'Quicksand', sans-serif;
  }
  
  .card-body h4.small,
  .card h4.small {
    font-size: 14px;
    font-weight: bold;
    color: #009999;
    text-transform: uppercase;
    margin-top: 0px;
  }
  
  
  .card .w-cont {
    padding: 0 20px 20px;
  }
  
  .titulo-ac span,
  .card .content h3 span {
    width: 36px;
    height: 36px;
    background: #EFEFEF;
    line-height: 36px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  /*--FIN ACORDEON--*/
  
  .paginador {
    margin-bottom: 20px;
  }
  
  .paginador span,
  .paginador a {
    font-size: 14px;
    font-weight: 500;
    color: #747474;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
  }
  
  .paginador a.nav {
    width: auto;
    padding: 0 20px;
    text-transform: uppercase;
  }
  
  .paginador span.nav {
    width: auto;
    padding: 0 20px;
    text-transform: uppercase;
    color: #fff;
    background: #E4E4E4;
    border-color: #E4E4E4;
  }
  
  .paginador a.activo,
  .paginador a.activo:hover {
    background: #009999;
    color: #fff;
  }
  
  .paginador a:hover {
    background: #e4e4e4;
  }
  
  /*--MISION--*/
  .mision {}
  
  .mision figure {
    position: relative;
  }
  
  .mision figure img {
    width: 100%;
  }
  
  .mision figure h2 {
    font-size: 32px;
    font-weight: bold;
    color: #fff;
    margin: 0;
    position: absolute;
    bottom: 5px;
    left: 15px;
    text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
    display: none;
  }
  
  .mision h3.t-3 {
    margin-bottom: 10px;
  }
  
  .mision .w-cont p {
    margin-bottom: 30px;
  }
  
  /*--FIN MISION--*/
  
  /*--DIRECTORIO--*/
  .directorio {
    padding: 40px 0;
  }
  
  .directorio figure {
    display: none;
  }
  
  .administracion {}
  
  .administracion p {
    margin-bottom: 6px;
  }
  
  .item-descarga {
    color: #3e3737;
    font-size: 16px;
    -webkit-box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.2);
    display: block;
    max-width: 530px;
    padding: 20px;
    position: relative;
    margin-bottom: 10px;
  }
  
  .item-descarga i,
  .item-descarga em {
    width: 40px;
    height: 40px;
    background: #009999;
    line-height: 38px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    position: absolute;
    right: 20px;
    top: 17px;
  }
  
  .banner-mobile {
    display: none;
    position: relative;
  }
  
  .banner-mobile img {
    width: 100%;
  }
  
  .banner-mobile h2 {
    font-size: 32px;
    font-weight: bold;
    color: #fff;
    margin: 0;
    position: absolute;
    bottom: 5px;
    left: 15px;
    text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
  }
  
  /*--FIN DIRECTORIO--*/
  
  /*--BENEFICIOS--*/
  .beneficios {
    padding: 40px 0;
  }
  
  .beneficios h3.t-3 {
    margin-bottom: 10px;
  }
  
  .beneficios p {
    margin-bottom: 30px;
  }
  
  .article-beneficio {}
  
  .article-beneficio figure {
    position: relative;
  }
  
  .article-beneficio figure img {
    width: 100%;
  }
  
  .article-beneficio figure h3 {
    font-size: 21px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    padding: 5px 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0;
    width: 100%;
    text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4);
    background: rgb(71,71,71);
    background: linear-gradient(0deg, rgba(71,71,71,0.71) 0%, rgba(0,212,255,0) 100%);
  }
  
  .article-beneficio figure h3 a {
    color: #fff;
  }
  
  .article-beneficio .texto-beneficio {
    padding: 12px;
    -webkit-box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.2);
  }
  
  .article-beneficio .texto-beneficio p {
    font-size: 1rem;
    color: #4a4a4a;
    line-height: 150%;
    margin-bottom: 15px;
  }
  
  .article-beneficio .texto-beneficio p b {}
  
  /*--FIN BENEFICIOS--*/
  
  /*--BUSQUEDA--*/
  .lista-noticias {
    padding: 10px 0;
  }
  
  .item-noticia {
    font-family: 'Quicksand', sans-serif;
    margin-bottom: 34px;
  }
  
  .item-noticia h4 {
    font-size: 18px;
    font-weight: bold;
    color: #009999;
  }
  
  .item-noticia h4 a {
    color: #009999;
    font-size: 18px;
  }
  
  .item-noticia p {
    font-size: 14px;
    margin-bottom: 0px;
  }
  
  .item-noticia a {
    color: #333333;
    font-size: 12px;
  }
  
  .item-noticia a:hover {
    text-decoration: underline;
  }
  
  /*--FIN BUSQUEDA--*/
  
  /*--BUSCADOR--*/
  .buscador {
    height: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 999;
    transition: cubic-bezier(.77, 0, .175, 1) .5s;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    padding: 20px;
  }
  
  .buscador.visible {
    opacity: 1;
    height: 100vh;
    -webkit-transform: translateY(-0%);
    -ms-transform: translateY(-0%);
    transform: translateY(-0%);
    background-color: rgba(0,0,0,.85);
  }
  
  .buscador .wrap-buscador {
    width: 700px;
    max-width: 100%;
  }
  
  .buscador .wrap-buscador .cerrar-buscador {
    position: absolute;
    top: 0px;
    right: 0px;
    color: #fff;
    width: 44px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 20px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .buscador .wrap-buscador label {
    font-size: 21px;
    color: #fff;
    margin-bottom: 5px;
    display: block;
  }
  
  .buscador .wrap-buscador input {
    height: 44px;
    width: 100%;
    font-family: 'Quicksand', sans-serif;
    margin-bottom: 12px;
    background: none;
    border: 0;
    border-bottom: 2px solid #fff;
    color: #fff;
  }
  
  .buscador .wrap-buscador button {
    font-family: 'Quicksand', sans-serif;
  }
  
  /*--FIN BUSCADOR--*/
  
  /*--SIMULACIONES--*/
  .box-simulador {
    -webkit-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
    padding: 30px 20px;
    border-radius: 8px;
    position: relative;
  }
  
  .box-simulador .form-group {
    position: relative;
  }
  
  .box-simulador .form-group label {
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    color: #333333;
    display: block;
    margin-bottom: 0px;
  }
  
  .box-simulador .form-group label span {
    color: #FF0000;
    font-size: 24px;
    display: inline-block;
    vertical-align: middle;
    line-height: 9px;
  }
  
  .box-simulador .form-group input {
    height: 39px;
    width: 100%;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    padding: 5px 10px;
    color: #333333;
    font-size: 14px;
    font-family: 'Quicksand', sans-serif;
  }
  
  .box-simulador .form-group input.input-fono {
    padding-left: 70px;
  }
  
  .box-simulador .botones-sim .btn.btn-sin-borde {
  border: 0;
  color: #6d6a6a;
  font-size: 1rem;
  padding-right: 0;
  border-radius: 0;
  padding-bottom: 0;
  padding-left: 0;
  width: auto;
  display: inline-block;
  border-bottom: 1px dotted #a7a2a2;
  }
  
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #CCCCCC;
  }
  
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #CCCCCC;
  }
  
  :-ms-input-placeholder {
    /* IE 10+ */
    color: #CCCCCC;
  }
  
  :-moz-placeholder {
    /* Firefox 18- */
    color: #CCCCCC;
  }
  
  * {
    outline: none;
  }
  
  .box-simulador .pre-fono {
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    color: #333333;
    position: absolute;
    height: 39px;
    width: 60px;
    background: #EFEFEF;
    line-height: 38px;
    border-radius: 4px 0 0 4px;
    top: 21px;
    text-align: center;
    padding: 0 4px;
  }
  
  .box-simulador .btn {
    width: 300px;
    max-width: 100%;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .box-simulador .btn.btn-full {
    width: 100%;
  }
  
  .tabla-tramos {
    border-radius: 0 0 8px 8px;
    -webkit-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
    margin-top: 30px;
  }
  
  .tabla-tramos h4 {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    margin: 0;
    background: #009999;
    padding: 16px 10px;
    text-transform: uppercase;
    text-align: center;
  }
  
  .tabla-tramos table {
    color: #666666;
    width: 100%;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
  }
  
  .tabla-tramos table tr {}
  
  .tabla-tramos table tr th {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    text-align: center;
    color: #666666;
    text-align: center;
    padding: 10px 20px;
    border: 1px solid #DCDCDC;
    width: 50%;
    background: #fff;
  }
  
  .tabla-tramos table tr td {
    color: #666666;
    font-size: 16px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    text-align: center;
    border: 1px solid #DCDCDC;
    padding: 10px;
  }
  
  .tabla-tramos table tr:nth-child(odd) {
    background: #F9F9F9;
  }
  
  .ver-primas {
    display: none;
  }
  
  .cerrar-tabla {
    display: none;
  }
  
  .botones-sim {
    text-align: center;
    margin: 0 auto;
    padding: 0 40px;
  }
  
  .botones-sim .btn {
    width: 100%;
  }
  
  .box-simulador .titular {
    font-size: 12px;
    color: #fff;
    font-weight: bold;
    background: #00888B;
    display: inline-block;
    padding: 4px 20px;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .morado .box-simulador .titular {
    background: #6C2183;
  }
  
  
  .azul .box-simulador .titular {
    background: #1d3e8b;
  }
  
  .naranjo .box-simulador .titular {
    background: #FF6C2C;
  }
  
  .box-simulador p {
    color: #333333;
    font-family: 'Quicksand', sans-serif;
    font-size: 1rem;
    margin-top: 20px;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .box-simulador-siniestro p {
    text-transform: capitalize;
  }
  
  .termininos-siniestro {
      display: flex;
      margin-right: 10px;
  }
  
  .box-simulador .form-check-label {
    font-size: 14px;
    color: #333333;
    font-family: 'Quicksand', sans-serif;
    margin-bottom: 40px;
  }
  
  .box-simulador .form-check-label input {}
  
  .pop-simulador {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    text-align: center;
    padding: 20px;
    margin-top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }
  
  .pop-simulador.visible {
    opacity: 1;
    visibility: visible;
  }
  
  .wrap-pop {
    background: #fff;
    position: relative;
    padding: 10px;
    width: 350px;
    max-width: 100%;
    margin: 0 auto;
    text-align: left;
  }
  
  .wrap-pop .cerrar-pop {
    width: 32px;
    height: 32px;
    background: #707070;
    text-align: center;
    line-height: 32px;
    color: #fff;
    display: block;
    font-size: 20px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 10;
  }
  
  .wrap-pop h4 {
    position: relative;
    font-size: 12px;
    width: 100%;
    color: #999999;
    background: transparent;
    text-align: left;
    padding-right: 40px;
    margin-bottom: 15px;
  }
  
  .wrap-pop .form-group {
    padding: 0 10px;
  }
  
  .wrap-pop .form-group label {
    font-size: 14px;
    color: #333333;
    font-family: 'Quicksand', sans-serif;
    margin-bottom: 0;
    display: block;
  }
  
  .wrap-pop .form-group input {
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    height: 39px;
    padding: 5px 10px;
    width: 100%;
  }
  
 
  
  .box-simulador .form-check.s-m {
    margin-top: 18px;
  }
  
  .box-simulador .form-check.s-m .form-check-label {
    margin-bottom: 0;
  }
  
  .adicionales {
    position: relative;
    margin-top: 10px;
  }
  
  .adicionales h4 {
    font-size: 12px;
    color: #fff;
    font-weight: bold;
    background: #00888B;
    display: inline-block;
    padding: 6px 20px;
    margin-left: -20px;
  }
  
  .morado .adicionales h4 {
    background: #6C2183;
  }
  
  .naranjo .adicionales h4 {
    background: #FF6C2C;
  }
  
  .adicionales .item-ad {
    position: relative;
    width: 50%;
  }
  
  .adicionales .item-ad p {
    color: #333333;
    font-size: 14px;
    font-family: 'Quicksand', sans-serif;
    margin-top: 16px;
  }
  
  .adicionales .item-ad p span {
    color: #999999;
  }
  
  .adicionales .item-ad i {
    position: absolute;
    top: 0;
    right: 0;
    color: #666666;
    font-size: 20px;
    cursor: pointer;
  }
  
  .box-simulador.s-p {
    padding: 0;
  }
  
  .box-simulador .wrap-tabla {
    padding-top: 40px;
  }
  
  .box-simulador table {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .box-simulador table tr {}
  
  .box-simulador table tr th {
    color: #333333;
    font-size: 12px;
    font-family: 'Quicksand', sans-serif;
    text-align: center;
    font-weight: normal;
    padding: 5px 15px;
    vertical-align: bottom;
    background: #fff;
  }
  
  .box-simulador table tr th span {
    font-size: 9px;
    color: #999999;
    display: block;
  }
  
  .box-simulador table tr td {
    text-align: center;
    font-size: 14px;
    color: #333333;
    font-family: 'Quicksand', sans-serif;
    padding: 14px 15px;
  }
  
  .box-simulador table tr td span {
    font-size: 10px;
    text-transform: uppercase;
    color: #888;
    display: inline-block;
    margin-right: 10px;
  }
  
  .box-simulador table tr th:first-child,
  .box-simulador table tr td:first-child {
    text-align: left;
  }
  
  .box-simulador table tr:nth-child(odd) {
    background: #EFEFEF;
  }
  
  .box-simulador table tr td:last-child {
    font-weight: bold;
    font-size: 16px;
  }
  
  .box-simulador table tr:last-child {}
  
  .box-simulador table tr:last-child td {
    border-top: 1px solid #333;
    padding: 6px 15px;
  }
  
  .fecha-simulacion {
    font-size: 14px;
    color: #333333;
    font-family: 'Quicksand', sans-serif;
    padding: 0 20px;
    margin-bottom: 20px;
  }
  
  .fecha-simulacion span {
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    color: #999999;
    display: block;
  }
  
  .box-simulador .edad {
    color: #333333;
    font-family: 'Quicksand', sans-serif;
    padding: 0 20px;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .box-simulador.s-p .botones-sim {
    padding-left: 20px;
    padding-right: 20px;
  
    padding-bottom: 30px;
  }
  
  .pop-email {
    text-align: center;
    border-radius: 10px;
    padding: 15px 15px 50px;
  }
  
  .pop-email h3 {
    font-size: 21px;
    color: #666666;
    font-weight: bold;
    margin: 0 0 30px;
    text-align: left;
  }
  
  .pop-email i {
    color: #CBCBCB;
    font-size: 100px;
  }
  
  /* CRZ box-simulador */
  
  /* SIMULADOR PASO 2 */
  
  
  @media (max-width: 1400px) {}
  
  @media (max-width: 1200px) {}
  
  @media (max-width: 991px) {
    header {
      padding: 10px 0;
    }
  
    header nav ul {
      display: none;
    }
  
    header .btn-menu {
      display: block;
    }
  
    .menu-mobile {
      width: 100%;
      max-width: 480px;
      padding: 20px 0;
      transition: cubic-bezier(.77, 0, .175, 1) .5s;
      display: block;
      position: absolute;
      top: 64px;
      background: #fff;
      z-index: 99;
      opacity: 0;
      visibility: hidden;
      min-height: calc(100vh - 64px);
    }
  
    .menu-mobile.visible {
      left: 0;
      opacity: 1;
      visibility: visible;
    }
  
    .botones-menu {
      padding: 20px;
    }
  
    .menu-mobile ul {
      list-style: none;
      padding-left: 0;
    }
  
    .menu-mobile ul li:hover {
      background: #099;
      color: #fff;
    }
  
    .menu-mobile ul li a {
      font-size: 1.1rem;
      padding: .5rem 0;
      margin: 0;
      color: #333333;
      font-family: 'Quicksand', sans-serif;
      display: block;
    }
  
    .menu-mobile ul.submenu {
      background: #F9F9F9;
      padding: 10px 0;
      position: relative;
      top: auto;
      z-index: 10;
      display: none;
    }
  
    .menu-mobile ul.submenu li a {
      font-size: 16px;
      font-family: 'Quicksand', sans-serif;
      color: #333333;
    }
  
    .menu-mobile ul li a i {
      font-size: 12px;
    }
  
    .menu-mobile ul li a:hover {
      /* background: #099; */
      color: #fff;
    }
  
    .texto-banner .descuento .des1 {
      font-size: 66px;
      line-height: 66px;
    }
  
    .texto-banner .descuento .des2 {
      font-size: 40px;
      line-height: 30px;
    }
  
    .texto-banner .descuento .des2 span {
      font-size: 14px;
      line-height: 10px;
    }
  
    .texto-banner .descuento h4 {
      font-size: 24px;
      line-height: 26px;
      margin: 0px 0 0;
    }
  
    .texto-banner a.btn {
      font-size: 16px;
      padding: 10px;
    }
  
    .botonera.botonera-desktop {
      display: none;
    }
  
    .botonera.botonera-mobile {
      display: flex;
      padding: 0;
    }
  
    .administracion {
      margin-top: 30px;
    }
  
    .article-beneficio {
      margin-bottom: 30px;
    }
  
    .c-b {
      border: none;
    }
  
    .box-simulador .b-gris {
      background: #F5F5F5;
      padding: 20px 15px;
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 768px) {
  
    .animated,
    .animated-delay1,
    .animated-delay2,
    .animated-delay3,
    .animated-delay4,
    .wow {
      /*CSS transitions*/
      -o-transition-property: none !important;
      -moz-transition-property: none !important;
      -ms-transition-property: none !important;
      -webkit-transition-property: none !important;
      transition-property: none !important;
      /*CSS transforms*/
      -o-transform: none !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      -webkit-transform: none !important;
      transform: none !important;
      /*CSS animations*/
      -webkit-animation: none !important;
      -moz-animation: none !important;
      -o-animation: none !important;
      -ms-animation: none !important;
      animation: none !important;
      visibility: visible !important;
    }
  
    .texto-banner {
      left: 40px;
    }
  
    .logo-seguro {
      top: 20px;
      right: 60px;
    }
  
    .logo-seguro img {
      width: 100px;
    }
  
    .servicios-home {
      flex-wrap: wrap;
    }
  
    .servicios-home .item-serv {
      width: calc(50% - 20px);
      margin-bottom: 10px;
      height: 136px;
    }
  
    .box-article {
      margin-bottom: 30px;
    }
  
    .datos-contacto {
      padding: 0 0 40px;
    }
  
    .datos-contacto .flex-center {
      flex-wrap: wrap;
    }
  
    .datos-contacto .item-dato {
      width: 100%;
      margin-bottom: 20px;
      padding-left: 50px;
    }
  
    .datos-contacto .item-dato i {
      left: 0;
      width: 40px;
      text-align: center;
    }
  
    footer .flex-between {
      flex-wrap: wrap;
    }
  
    footer .logo-footer {
      width: 100%;
      margin: 0 0 30px;
    }
  
    footer .m-footer {
      width: 48%;
      padding: 0;
      margin-bottom: 30px;
    }
  }
  
  @media (max-width: 640px) {
  
    footer .m-footer {
      width: 100%;
      padding: 0;
      margin-bottom: 30px;
    }
  
    .botonera .item-botonera {
      flex-direction: column;
      text-align: center;
      height: 90px;
      justify-content: center;
      padding: 10px;
    }
  
    .botonera .item-botonera i {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .w-beneficios {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      flex-wrap: wrap;
  
    }
  
    .item-beneficio {
      min-width: 48%;
      padding: 0;
      width: 48%;
      height: auto;
      flex-direction: column;
      padding: 0;
    }
  
    .item-beneficio .icono {
      width: 100%;
      margin: 0;
      height: 100px;
    }
  
    .item-beneficio p {
      padding: 10px 10px 15px;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
    }
  
    .buscador {
      display: block;
      background: #fff;
      top: 64px;
    }
  
    .buscador.visible {
      overflow: visible;
    }
  
    .buscador .wrap-buscador {
      box-shadow: none;
      padding: 0;
      position: static;
    }
  
    .buscador .wrap-buscador .cerrar-buscador {
      position: absolute;
      color: #FF6C2C;
      top: -55px;
      right: 39px;
      background: #fff;
      width: 44px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      font-size: 20px;
      border-radius: 0;
      cursor: pointer;
    }
  
    .ver-primas {
      display: inline-block;
    }
  
    .tabla-tramos {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      text-align: center;
      padding: 20px;
      margin-top: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      flex-direction: column;
      background: rgba(0, 153, 153, 0.7);
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
    }
  
    .tabla-tramos.visible {
      opacity: 1;
      visibility: visible;
    }
  
    .tabla-tramos table {
      background: #fff;
    }
  
    .tabla-tramos h4 {
      position: relative;
      font-size: 12px;
      width: 100%;
      color: #999999;
      background: #fff;
      text-align: left;
      padding-right: 40px;
    }
  
    .cerrar-tabla {
      width: 32px;
      height: 32px;
      background: #707070;
      text-align: center;
      line-height: 32px;
      color: #fff;
      display: block;
      font-size: 20px;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  
    .botones-sim {
      padding: 0;
    }
  
    .adicionales .item-ad {
      width: 100%;
    }
  
    .box-simulador table tr td span {
      display: block;
    }
  
    .wrap-tabla {
      overflow: scroll;
    }
  
    .box-simulador table tr td,
    .box-simulador table tr th {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  
  @media (max-width: 480px) {
  
    .wrap {
      padding: 0 14px;
    }
  
    .banner .wrap {
      padding: 0;
    }
  
    .logo-seguro {
      right: auto;
      left: 58px;
    }
  
    .logo-seguro img {
      width: 110px;
    }
  
    .texto-banner {
      width: 180px;
      left: 20px;
      top: 120px;
    }
  
    .texto-banner .descuento {
      padding: 20px;
    }
  
    .texto-banner .descuento h4 {
      font-size: 19px;
      line-height: 22px;
    }
  
    .texto-banner a.btn {
      font-size: 14px;
    }
  
    .servicios-home {
      padding-left: 0;
      padding-right: 0;
    }
  
    .servicios-home .item-serv h4 {
      font-size: 14px;
    }
  
    .card-header.collapsed:after,
    .card-header:not(.collapsed):after {
      width: 30px;
      height: 30px;
      top: 20px;
      right: 10px;
      padding-top: 7px;
    }
  
    .card-header:not(.collapsed):after {
      padding-top: 5px;
    }
  
    .seguros picture {
      display: block;
      margin: 0 -15px;
      position: relative;
    }
  
    .seguros picture h3 {
      display: block;
      font-size: 28px;
      font-weight: bold;
      color: #fff;
      margin: 0;
      position: absolute;
      bottom: 45px;
      left: 15px;
      text-shadow: 0px 1px 6px rgba(0, 0, 0, 1);
    }
  
    .botones-seguro {
      position: relative;
    }
  
    .botones-seguro .btn {
      padding: 8px 10px;
    }
  
    .seguros h3.t-3 {}
  
    .mision figure {
      margin: 0 -14px;
    }
  
    .banner-mobile {
      margin: 0 -15px 20px;
    }
  
    .mision figure:after,
    .banner-mobile:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      z-index: 1;
    }
  
    
   
  
    .banner-mobile h2 {
      z-index: 10;
      bottom: 12px;
    }
  
    .mision figure h2 {
      display: block;
      bottom: 10px;
      z-index: 10;
      text-transform: none;
      font-size: 28px;
    }
  
    .mision h2.t-2 {
      display: none;
    }
  
    .mision figure {
      display: block;
    }
  
    .directorio {
      padding: 0 0 40px;
    }
  
    .directorio figure {
      margin-bottom: 20px;
    }
  
    .mision.directorio h2.t-2 {
      display: none;
    }
  
    .banner-mobile {
      display: block;
    }
  
    .beneficios .t-3 {
      display: none;
    }
  
    .paginador {
      text-align: center;
    }
  
    .paginador span,
    .paginador a {
      font-size: 12px;
      width: 24px;
    }
  
    .paginador a.nav,
    .paginador span.nav {
      padding: 0 10px;
    }
  
    .buscador .wrap-buscador .cerrar-buscador {
      position: absolute;
      color: #FF6C2C;
      top: -65px;
      right: 0;
      background: #fff;
      width: 68px;
      height: 66px;
      line-height: 62px;
      text-align: center;
      font-size: 30px;
      border-radius: 0;
      cursor: pointer;
    }
  
    .beneficios {
      padding-top: 0;
    }
  
    .box-simulador .form-check.s-m {
      margin-bottom: 10px;
    }
  
    .t-5.paso-sim {
      position: relative;
      padding-left: 45px;
    }
  
    .t-5 span {
      position: absolute;
      left: 0;
      top: -6px;
    }
  
  @media (max-width: 550px) {
    .t-5.paso-sim {
      font-size: 0;
      width: auto;
      max-width: 200px;
    }
    .t-5.paso-sim.activo {
      font-size: 16px;
    }
  }
  
    .box-simulador .form-group .form-check-inline {
      margin-right: 50px;
    }
  
    .fecha-box {
      flex-wrap: wrap;
    }
  
    .fecha-box p {
      width: 100%;
      margin-right: 0;
      margin-top: 0;
    }
  
    section.mision.directorio h3.t-3 {
      display: none;
    }
  
    img.assa-mision {
      display: none;
    }
  }
  
  @media (max-width: 320px) {
    .logo-seguro {
      top: 10px;
    }
  
    .logo-seguro img {
      width: 100px;
    }
  
    .texto-banner {
      top: 100px;
    }
  }
  
  
  .css-error {
    color: #FF0000;
    font-size: 12px;
  }
  
  
  @media (min-width: 768px) {
    .specials-buttons-home a {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
  
    .main-slider .slide-inner {
      max-width: 1100px;
      margin: 0 auto;
      position: absolute !important;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      align-items: center;
      display: flex;
      justify-content: flex-end;
    }
  
    .main-slider .tns-nav {
      display: none;
    }
  
    .main-slider .tns-nav button {
      border-color: white;
    }
  
    .tns-nav button.tns-nav-active {
      background-color: white;
    }
  
    .specials-buttons-home a[class^=rescate-alemana] {
      display: none;
    }
  }
  
  .main-slider .slide {
    height: auto;
    background-size: cover;
    width: 100%;
    position: relative;
    border: 0;
  }
  
  .main-slider .slide .slide-inner {
    position: relative;
    height: 100%;
    text-shadow: 1px 1px 2px #495057;
    color: white;
  }
  
  .main-slider .slide .slide-inner .btn-success {
    color: #fff;
    background-color: #ea6607;
    border-color: #ea6607;
    align-self: flex-end;
    margin-bottom: 4rem;
  }
  
  .main-slider .slide .slide-inner .content-slide {
    position: absolute;
    height: 100%;
    right: 0;
    width: 525px;
  }
  
  .main-slider .slide .slide-inner .content-slide button {
    position: absolute;
    bottom: 0;
    margin-bottom: 80px;
    font-size: 18px;
    padding: 10.5px 32.5px;
    right: 50%;
    transform: translatex(50%);
  }
  
  .main-slider .slide .slide-inner .content-slide button a {
    color: #fff;
  }
  
  .main-slider {
    position: relative;
    opacity: 0;
    transition: all 0.3s ease;
  }
  
  .main-slider.visible {
    opacity: 1;
  }
  
  .main-slider .tns-nav {}
  
  .main-slider .tns-controls button {
    border: 2px solid white;
    background: transparent;
    border-radius: 5px;
    color: white;
    font-size: 0;
    width: 36px;
    height: 36px;
    line-height: 29px;
    text-align: center;
    position: absolute;
    z-index: 10;
  }
  
  .main-slider .tns-controls button {
    bottom: 20px;
    transition: all 0.3s ease;
  }
  
  .main-slider .tns-controls button:hover {
    background: #23a19b;
    color: #fff;
  }
  
  .main-slider .tns-controls button:first-child {
    right: 106px;
  }
  
  .main-slider .tns-controls button:last-child {
    right: 60px;
  }
  
  .main-slider .tns-controls button:after {
  
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
  }
  
  .main-slider .wrap {
    position: relative;
  }
  
  .main-slider .tns-controls button:first-child:after {
    content: "\f053";
  }
  
  .main-slider .tns-controls button:last-child:after {
    content: "\f054";
  }
  
  .tns-nav button {
    width: 12px !important;
    height: 12px;
    display: inline-block;
    border: 1px solid #008480;
    border-radius: 100%;
    margin: 0 6px;
    background: transparent;
    padding: 0 !important;
  }
  
  .main-slider .slide .slide-inner {
    padding-right: 40px;
  }
  
  @media (max-width: 767.98px) {
    .specials-buttons-home a {
      padding: 18px 0;
    }
  
    .main-slider .slide .slide-inner {
      position: absolute;
      bottom: 0;
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      padding-bottom: 20px;
      align-items: flex-start;
    }
  
    .social-icons {
      display: none;
    }
  
    .main-slider .tns-nav {
      display: none;
    }
  
    .main-slider .slide .slide-inner {
      padding-right: 0;
    }
  }
  
  @media (max-width: 640px) {
  
    .main-slider .wrap {
      padding: 0 20px;
    }
  
  }
  
  @media (max-width: 480px) {
    .landing-banner{
      position: absolute;
      bottom: 20px;
      z-index: 10;
      padding-top: 20px;
      padding-bottom: 20px;
      color: #fff;
    }
    .main-slider .wrap {
      padding: 0 0px;
    }
  
    .main-slider .tns-controls button:first-child {
      right: 66px;
    }
  
    .main-slider .tns-controls button:last-child {
      right: 20px;
    }
  
    .main-slider .slide .slide-inner .btn-success {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: bold;
      align-self: flex-start;
    }
  }
  @media (max-width: 640px) {
    .visible#pop-primas .cerrar-tabla {
      display: none;
    }
  }
  
  .b-mobile {
    display: none;
  }
  
  @media (max-width: 480px) {
    .b-mobile {
      display: block;
      margin-bottom: 20px;
    }
  }
  
  .box-simulador .form-group input#datepicker {
    position: relative;
    z-index: 2;
    background: transparent;
    }
  
    .ic-cal { z-index: 1; }
  
  
  #ui-id-1,
  #ui-id-2 {
    position: relative;
    max-width: 700px;
    z-index: 999999;
    margin: 0;
    background-color: #fff;
    box-shadow: 0 0 5px #ccc;
    padding: .5rem 0;
    list-style: none;
    width: calc(100% - 20px);
    max-height: 100vh;
    }
    
  #ui-id-1 .ui-menu-item-wrapper, 
  #ui-id-2 .ui-menu-item-wrapper {
    padding: .5rem 1rem;
    }
  
  .wrap-buscador .form-group {
    position: relative;
    display: flex;
    }
  
  .wrap-buscador button {
    height: 3rem;
    width: 3rem;
    border-radius: 3px 3px 3px 0;
    border: 0;
    background-color: #fff;
    font-size: 1.25rem;
    flex-shrink: 0;
    bottom: 10px;
    cursor: pointer;
    }