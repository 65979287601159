.ca-footer{
    height: 111px;
    background-color: $color-primary;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.menu-mobile{
    .m-item{
        padding-left: 20px;
        padding-right: 20px;
        font-size: 1.6rem;
    }

    .submenu{
        li a{
            padding-left: 20px;
            padding-right: 20px;
        font-size: 1.6rem;

        }
    }
}


// Mobile
@media screen and (max-width: $md) {
    footer {
      height: initial;
      text-align: center;
    }
  }